import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import {
  Container,
  Navbar,
  Spinner,
  Button
} from 'react-bootstrap'
import Image from "components/Image";
import Icon from "components/Icon";
import Footer from "containers/Footer";

import breakDownAllNodes from "utils/breakDownAllNodes";
import addZeroes from 'utils/addZeroes';
import queryString from 'query-string';

const ROME_API = "https://rome.routineslab.com/api_v0";

export const query = graphql`
  query CheckoutQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          title

          subtotal
          shipment
          total
          paypalButton

          copyright
          social {
            linkedin
          }
          privacyText
          termsText
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const CheckoutPage = ({ pathContext, data, location }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const {
    checkoutNode,
    footerNode
  } = breakDownAllNodes(nodes);

  const { frontmatter: { title, subtotal, shipment, total, paypalButton } } = checkoutNode;

  const { c: code } = queryString.parse(location.search); 

  const [firstData, setFirstData] = useState({ n_units: 1 });
  const [secondData, setSecondData] = useState(null);

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isSecondLoading, setIsSecondLoading] = useState(true);

  useEffect(() => {
    // Fetching first data 
    if(code) {
      fetch(`${ROME_API}/display?code=${code}`)
      .then(res => res.json())
      .then(resData => setFirstData(prev => ({ ...prev, ...resData})))
      .finally(() => setIsFirstLoading(false))
    }
  }, [code])

  useEffect(() => {
    // Fetching second data 
    if(firstData.n_units > 0 && code) {
      setIsSecondLoading(true)
      fetch(`${ROME_API}/costs?code=${code}&country=DE&units=${firstData?.n_units}&vat=true`)
      .then(res => res.json())
      .then(resData => setSecondData({
        subtotal: addZeroes(resData.subtotal),
        shipment: addZeroes(resData.shipment),
        total: addZeroes(resData.total)
      }))
      .finally(() => setIsSecondLoading(false))
    }
  }, [firstData, code])

  const increaseUnit = () => setFirstData(prev => ({ ...prev, n_units: prev.n_units+1 }))
  const decreaseUnit = () => setFirstData(prev => ({ ...prev, n_units: prev.n_units-1 }))
    
  return (
    <>
      <Helmet title={`${title} @ ${firstData?.account?.display_name}`} />
      {firstData?.account ? (
        <Container 
          fluid
          className="p-0 overflow-auto h-100"
          style={{ backgroundImage: `linear-gradient(180deg, ${firstData?.account?.design?.secondary_color}, white)` ?? 'white' }}  
        >
          <Navbar 
            variant="dark" 
            fixed="top"
            style={{ backgroundColor: firstData?.account?.design?.primary_color ?? 'white' }} 
          >
            <h1 style={{ fontSize: '1.5rem' }} className="font-weight-normal mx-auto">
              {firstData?.account?.display_name}
            </h1>
          </Navbar>
      
          <main className="d-flex flex-column align-items-center checkout-page">
            <img
              className="checkout-account-logo"
              src={firstData?.account?.design?.logo}
              alt="account logo"
            />
      
            <img
              style={{ width: '200px', height: '200px' }}
              src={firstData?.product?.image}
              alt={firstData?.product?.name ?? 'image'}
            />
            <h4 className="mt-1 mt-md-3 mb-0 mb-md-1 font-weight-normal">
              {firstData?.product?.name}
            </h4>
            <ul className="list-unstyled">
              {firstData?.specifications?.map(({ name }) => <li className="text-black-50" key={name}>{name}</li>)}
            </ul>
      
            <div className="w-100 px-2 my-md-4 d-flex flex-row align-items-center justify-content-between">
              <button type="button" className="btn-plusmin" onClick={decreaseUnit} disabled={firstData?.n_units <= 1 || isSecondLoading}>
                <Icon iconName="MinusIcon" />
              </button>
              <div className="d-flex flex-column align-items-center">
                <span>{firstData?.product?.unit}</span>
                <span style={{ fontSize: '1.5rem' }}>{firstData?.n_units}</span>
                <span className="text-black-50">{firstData?.product?.content_per_unit}</span>
              </div>
              <button type="button" className="btn-plusmin" onClick={increaseUnit} disabled={firstData?.n_units >= firstData?.max_units || isSecondLoading}>
                <Icon iconName="PlusIcon" />
              </button>
            </div>
      
            <div className="row mt-2" style={{ width: '100%' }}>
              <div className="col">
                {subtotal}
              </div>
              <div className="col text-right">{isSecondLoading ? <Spinner animation="border" size="sm" /> : `${secondData?.subtotal} €`}</div>
            </div>
            <div className="row" style={{ width: '100%' }}>
              <div className="col">
                {shipment}
              </div>
              <div className="col text-right">{isSecondLoading ? <Spinner animation="border" size="sm" /> : `${secondData?.shipment} €`}</div>
            </div>
            <div className="row font-weight-bold" style={{ width: '100%' }}>
              <div className="col">
                {total}
              </div>
              <div className="col text-right">{isSecondLoading ? <Spinner animation="border" size="sm" /> : `${secondData?.total} €`}</div>
            </div>
      
            <div className="row w-100">
              <div className="col">
                <Button 
                  type="button"
                  variant="warning"
                  className="mt-3 mt-md-5 px-4 py-1 d-flex align-items-center justify-content-center font-weight-bold w-100"
                  style={{ border: '1px solid gray' }}
                  // eslint-disable-next-line no-alert
                  onClick={() => alert('Dies ist nur eine Demo vom RoutinesLab!')}
                >
                  {paypalButton}
                  <Image
                    fileName="paypal.png"
                    style={{ width: '100px' }}
                    className="ml-2"
                  />
                </Button>
              </div>
            </div>
          </main>
          
          <Footer frontmatter={footerNode.frontmatter} pathContext={pathContext} className="footer-checkout" />
        </Container>
      ) : (
        <Container 
          fluid
          className="p-0 overflow-auto h-100 d-flex justify-content-center align-items-center text-dark"
          style={{ backgroundColor: 'white' }} 
        >
          {isFirstLoading && code ? (
            <Spinner animation="border" />
          ) : (
            <p>No data.</p>
          )}
        </Container>
      )}
    </>
  )
}

CheckoutPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pathContext: PropTypes.object
};

CheckoutPage.defaultProps = {
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default CheckoutPage